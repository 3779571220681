/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'preferences': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<g _fill="#14193D" fill-rule="nonzero"><path pid="0" d="M11.136 15.952l.376-.097a6.007 6.007 0 001.585-.662l.334-.2 1.482 1.482 1.607-1.607-1.49-1.49.194-.334c.28-.478.492-.993.631-1.532l.097-.376h2.272V8.864h-2.272l-.097-.376a6.008 6.008 0 00-.71-1.663l-.208-.337 1.538-1.537-1.607-1.608-1.573 1.573-.33-.186a6.004 6.004 0 00-1.453-.585l-.376-.097V1.821H8.864v2.227l-.376.097a6.006 6.006 0 00-1.559.646l-.333.197L4.95 3.343 3.343 4.951l1.645 1.645-.197.333a6.006 6.006 0 00-.646 1.56l-.097.375h-2.27v2.272h2.27l.097.376c.131.509.328.996.585 1.452l.186.331-1.573 1.573 1.608 1.607 1.537-1.538.337.209a6.008 6.008 0 001.663.709l.376.097v2.283h2.272v-2.283zm1 .764v1.769a.75.75 0 01-.75.75H8.614a.75.75 0 01-.75-.75v-1.77a7.006 7.006 0 01-1.22-.519L5.48 17.36a.75.75 0 01-1.06 0l-1.961-1.96a.75.75 0 010-1.061l1.22-1.22a7.002 7.002 0 01-.396-.982H1.528a.75.75 0 01-.75-.75V8.614a.75.75 0 01.75-.75h1.756c.121-.38.274-.749.457-1.102L2.46 5.481a.75.75 0 010-1.06L4.42 2.46a.75.75 0 011.06 0L6.763 3.74a7.004 7.004 0 011.102-.457V1.571a.75.75 0 01.75-.75h2.772a.75.75 0 01.75.75v1.713c.337.108.665.24.981.396l1.22-1.22a.75.75 0 011.061 0l1.96 1.96a.75.75 0 010 1.06l-1.162 1.164c.211.389.385.797.52 1.22h1.758a.75.75 0 01.75.75v2.772a.75.75 0 01-.75.75h-1.758c-.118.37-.265.728-.442 1.072l1.13 1.13a.75.75 0 010 1.06l-1.96 1.96a.75.75 0 01-1.061 0l-1.115-1.115c-.363.19-.74.348-1.132.473z"/><path pid="1" d="M10 14.114a4.114 4.114 0 110-8.228 4.114 4.114 0 010 8.228zm0-1a3.114 3.114 0 100-6.228 3.114 3.114 0 000 6.228z"/></g>'
  }
})
